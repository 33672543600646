
<template>
   <div class="vs-row">
        <vs-row  vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
            <vs-row  vs-align="center" vs-type="flex" vs-justify="space-around" vs-w="12">
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                    <vs-card actionable class="cardx radial_quarter" vs-w="12">
                        <vs-col vs-type="flex-start" vs-justify="center" vs-align="center" vs-w="3">
                            <vue-apex-charts type=radialBar  height=240 :options="leadsOverviewRadialBar.chartOptions" :series="leadsOverviewRadialBar.leads"></vue-apex-charts> 
                            <h4 class="vs-radar-text">Q1</h4>
                            <!-- <h4 class="vs-radar-thisquarter-text"><small>Completed</small></h4>
                            <h4 class="vs-radar-thisquarter-text"><small>In Progress</small></h4>                  -->
                        </vs-col>
                        <vs-col vs-type="flex-end" vs-justify="center" vs-align="center" vs-w="3">
                            <vue-apex-charts type=radialBar  height=240 :options="leadsOverviewRadialBar.chartOptions" :series="leadsOverviewRadialBar.opportunities" ></vue-apex-charts>
                            <h4 class="vs-radar-text">Q2</h4>                                          
                        </vs-col>
                        <vs-col vs-type="flex-start" vs-justify="center" vs-align="center" vs-w="3">
                            <vue-apex-charts type=radialBar  height=240 :options="leadsOverviewRadialBar.chartOptions" :series="leadsOverviewRadialBar.accounts" ></vue-apex-charts>
                            <h4 class="vs-radar-text">Q3</h4>                
                        </vs-col>
                        <vs-col vs-type="flex-start" vs-justify="center" vs-align="center" vs-w="3">
                            <vue-apex-charts type=radialBar  height=240 :options="leadsOverviewRadialBar.chartOptions" :series="leadsOverviewRadialBar.today" ></vue-apex-charts>
                            <h4 class="vs-radar-thisquarter">This Quarter</h4>                
                        </vs-col> 
                    </vs-card>
                </vs-col>
                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="6">
                    <vs-card actionable class="cardx lineAreaChartSpline" vs-w="12">
                        <vue-apex-charts  type="area" height=240 :options="lineAreaChartSpline.chartOptions" :series="lineAreaChartSpline.series"></vue-apex-charts>
                    </vs-card>
                </vs-col>
                             
            </vs-row>                          
        </vs-row>
        <template lang="html">
          <div>
            <vx-card actionable class="cardx" title="Reports">
              <vs-table  max-items="10" :data="users">
                  <!-- <template slot="header">
                      <h3 class="table_heading">
                        Reports
                      </h3>
                  </template> -->
                  <template slot="thead" class="exc" slot-scope="{data}">
                      <vs-th >
                      Level
                      </vs-th>
                      <vs-th>
                      Level Discription
                      </vs-th>
                      <vs-th>
                      Priority
                      </vs-th>
                      <vs-th>
                      Follow Up (Suggested Time Tag)
                      </vs-th>
                      <vs-th>
                      Follow Up (Maximum Time Tag)
                      </vs-th>
                      <vs-th>
                      Database
                      </vs-th>
                      <vs-th>
                      Net Enquiry
                      </vs-th>
                      <vs-th>
                      Visitors
                      </vs-th>
                      <vs-th>
                      Elgibility
                      </vs-th>
                      <vs-th>
                      Loan
                      </vs-th>
                  </template>

                  <template slot-scope="{data}">
                      <vs-tr :style="{backgroundColor: (data[indextr].level=='M6'||data[indextr].level=='M5'||data[indextr].level=='M3+'||data[indextr].level=='M3' ||data[indextr].level=='M3++'? 'chartreuse' : 'transparent' && data[indextr].level=='M4'||data[indextr].level=='M2'||data[indextr].level=='N/A'||data[indextr].level=='OS'? '#d89177' : 'transparent'
                      && data[indextr].level=='L5'||data[indextr].level=='L4'||data[indextr].level=='L3+'||data[indextr].level=='L3'||data[indextr].level=='L2'? '#25d425' : 'transparent'&& data[indextr].level=='L4-'||data[indextr].level=='L2-'? '#f7ac039c' : 'transparent'&& data[indextr].level=='Total'? '#79e8e3' : 'transparent'
                       && data[indextr].level=='#N/A'||data[indextr].level=='BLANKS'? '#ffbc00' : 'transparent')}" :key="indextr" v-for="(tr, indextr) in data" >
                      <vs-td  :data="data[indextr].level" >
                          {{data[indextr].level}}
                      </vs-td>

                      <vs-td :data="data[indextr].level_description" >
                          {{data[indextr].level_description}}
                      </vs-td>

                      <vs-td :data="data[indextr].priority_level">
                          {{data[indextr].priority_level}}
                      </vs-td>

                      <vs-td :data="data[indextr].suggested_follow_up">
                          {{data[indextr].suggested_follow_up}}
                      </vs-td>

                      <vs-td :data="data[indextr].max_suggested_follow_up">
                          {{data[indextr].max_suggested_follow_up}}
                      </vs-td>

                      <vs-td :data="data[indextr].database" :style="{backgroundColor: (data[indextr].database>100?'red' : 'transparent')}">
                          {{data[indextr].database}}
                      </vs-td>

                      <vs-td :data="data[indextr].net_enquiry" :style="{backgroundColor: (data[indextr].net_enquiry>100?'red' : 'transparent')}">
                          {{data[indextr].net_enquiry}}
                      </vs-td>

                      <vs-td :data="data[indextr].visitors" :style="{backgroundColor: (data[indextr].visitors>100?'red' : 'transparent')}">
                          {{data[indextr].visitors}}
                      </vs-td>

                      <vs-td :data="data[indextr].eligibility" :style="{backgroundColor: (data[indextr].eligibility>100?'red' : 'transparent')}">
                          {{data[indextr].eligibility}}
                      </vs-td>

                      <vs-td :data="data[indextr].loan" :style="{backgroundColor: (data[indextr].loan>100?'red' : 'transparent')}">
                          {{data[indextr].loan}}
                      </vs-td>

                      </vs-tr>
                  </template>
                </vs-table>  
            </vx-card>
          </div>  
        </template>   
             
    </div>
   

</template>
<script>
// import VueApexCharts from vue-apexcharts
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'
export default {
    data:()=>({
    //   themeColors: ['#7367F0', '#28C76F', '#EA5455', '#FF9F43', '#1E1E1E'],
      lineAreaChartSpline: {
        series: [{
            name: 'Leads',
            data: [31, 40, 28, 51, 42, 109, 100,31, 40, 28, 51, 42, 109, 100]
          }, {
            name: 'Opportunities',
            data: [11, 32, 45, 32, 34, 52, 41,31, 40, 28, 51, 42, 109]
        },{
            name: 'Accounts',
            data: [20, 35, 49, 34, 38, 54, 44]
        }],
        chartOptions: {
          dataLabels: {
            enabled: true
          },
          stroke: {
            // curve: 'smooth'
            width:[2,2,2]
          },
        //   colors: themeColors,
          xaxis: {
            type: 'datetime',
            categories: ["2018-09-19T00:00:00", "2018-09-19T01:30:00", "2018-09-19T02:30:00",
              "2018-09-19T03:30:00", "2018-09-19T04:30:00", "2018-09-19T05:30:00",
              "2018-09-19T06:30:00", "2018-09-19T07:30:00", "2018-09-19T08:30:00",
              "2018-09-19T09:30:00", "2018-09-19T10:30:00", "2018-09-19T11:30:00",
              "2018-09-19T12:30:00",
            ],
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },

          }
        }
      
    },
    leadsOverviewRadialBar: {
        leads: [50],
        opportunities: [50],
        accounts: [40],
        today: [40],
        chartOptions: {
            plotOptions: {
                radialBar: {
                    size: 45,
                    startAngle: -150,
                    endAngle: 150,
                    hollow: {
                        size: ' 70%',
                    },
                    track: {
                        background: "#bfc5cc",
                        strokeWidth: '70%',
                    },
                    dataLabels: {
                        name: {
                            show: false
                        },
                        value: {
                            offsetY: 18,
                            color: '#99a2ac',
                            fontSize: '1.5rem'
                        }
                    }
                }
            },
            colors: ['#00db89'],
            fill: {
                type: 'gradient',
                gradient: {
                    shade: 'dark',
                    type: 'horizontal',
                    shadeIntensity: 0.5,
                    gradientToColors: ['#00b5b5'],
                    inverseColors: true,
                    opacityFrom: 1,
                    opacityTo: 1,
                    stops: [0, 100]
                },
            },
            stroke: {
                lineCap: 'round'
            },
            chart: {
                sparkline: {
                    enabled: true,
                },
                dropShadow: {
                    enabled: true,
                    blur: 3,
                    left: 1,
                    top: 1,
                    opacity: 0.1
                },
            },
        }
    },
    users:[
      {
        "level": "M6",
        "level_description": "Visited & Ready to Enroll",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 200,
        "net_enquiry": "190",
        "visitors": "206",
        "eligibility": "3",
        "loan": "1",
        "state":1,
      },
      {
        "level": "M5",
        "level_description": "Visited & Possitive",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 50  ,
        "net_enquiry": "20",
        "visitors": "53",
        "eligibility": "6",
        "loan": "0",
        "state":1
      },
      {
        "level": "M4",
        "level_description": "Visited but refused/postponed",
        "priority_level": "No Follow Up",
        "suggested_follow_up": "90days",
        "max_suggested_follow_up": "180days",
        "database": 19,
        "net_enquiry": "8",
        "visitors": "19",
        "eligibility": "2",
        "loan": "0",
        "state":0
      },
      {
        "level": "M3+",
        "level_description": "called & coming",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 97,
        "net_enquiry": "75",
        "visitors": "0",
        "eligibility": "4",
        "loan": "0",
        "state":1
      },
      {
        "level": "M3",
        "level_description": "called & possitive",
        "priority_level": "P1",
        "suggested_follow_up": "14days",
        "max_suggested_follow_up": "30days",
        "database": 269,
        "net_enquiry": "99",
        "visitors": "0",
        "eligibility": "4",
        "loan": "0",
        "state":1
      },
      {
        "level": "M2",
        "level_description": "Did not Visit & Refused/Postponed",
        "priority_level": "No Follow Up",
        "suggested_follow_up": "90days",
        "max_suggested_follow_up": "180days",
        "database": 2,
        "net_enquiry": "0",
        "visitors": "0",
        "eligibility": "0",
        "loan": "0",
        "state":0
      },
      {
        "level": "L5",
        "level_description": "Visited & Positivem(Last Batch) but did not Join",
        "priority_level": "P2",
        "suggested_follow_up": "15days",
        "max_suggested_follow_up": "60days",
        "database": 168,
        "net_enquiry": "109",
        "visitors": "203",
        "eligibility": "27",
        "loan": "0",
        "state":2
      },
      {
        "level": "L4",
        "level_description": "Visited(Last Batch); but postponed & did not Join",
        "priority_level": "P2",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 43,
        "net_enquiry": "10",
        "visitors": "20",
        "eligibility": "6",
        "loan": "1",
        "state":2
      },
      {
        "level": "L4-",
        "level_description": "Visited but refused/postponed",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 43,
        "net_enquiry": "10",
        "visitors": "20",
        "eligibility": "6",
        "loan": "1",
        "state":3
      },
      {
        "level": "L3+",
        "level_description": "Visited but refused/postponed",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 43,
        "net_enquiry": "10",
        "visitors": "20",
        "eligibility": "6",
        "loan": "1",
        "state":2
      },
      {
        "level": "L3",
        "level_description": "Visited but refused/postponed",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 43,
        "net_enquiry": "10",
        "visitors": "20",
        "eligibility": "6",
        "loan": "1",
        "state":2
      },
      {
        "level": "L2",
        "level_description": "Visited but refused/postponed",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 43,
        "net_enquiry": "10",
        "visitors": "20",
        "eligibility": "6",
        "loan": "1",
        "state":2
      },
      {
        "level": "L2-",
        "level_description": "Visited but refused/postponed",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 143,
        "net_enquiry": "10",
        "visitors": "20",
        "eligibility": "6",
        "loan": "1",
        "state":2
      },
      {
        "level": "N/A",
        "level_description": "Visited but refused/postponed",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 43,
        "net_enquiry": "10",
        "visitors": "20",
        "eligibility": "6",
        "loan": "1",
        "state":0
      },
      {
        "level": "OS",
        "level_description": "Visited but refused/postponed",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 243,
        "net_enquiry": "10",
        "visitors": "20",
        "eligibility": "6",
        "loan": "1",
        "state":0
      },
      {
        "level": "Total",
        "level_description": "Visited but refused/postponed",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 43,
        "net_enquiry": "10",
        "visitors": "20",
        "eligibility": "6",
        "loan": "1",
        "state":4
      },
      {
        "level": "#N/A",
        "level_description": "Visited but refused/postponed",
        "priority_level": "P1",
        "suggested_follow_up": "7days",
        "max_suggested_follow_up": "14days",
        "database": 43,
        "net_enquiry": "10",
        "visitors": "20",
        "eligibility": "6",
        "loan": "1",
        "state":5
      },
      {
        "level": "BLANKS",
        "level_description": "",
        "priority_level": "",
        "suggested_follow_up": "",
        "max_suggested_follow_up": "14days",
        "database": 43,
        "net_enquiry": "10",
        "visitors": "20",
        "eligibility": "6",
        "loan": "1",
        "state":5
      },
    ]
  }),
  components: {
        VueApexCharts,
        StatisticsCardLine,
        ChangeTimeDurationDropdown
    },
}
</script>
<style>
.vs-radar-text{
    color: darkgray;
    position: relative;
    bottom: 70px;
    left: 35px;
    font-size: larger;
}
.radial_quarter{
    position: relative;
    height: 260px;
}
.lineAreaChartSpline{
    position: relative;
    height: 260px;
}
.vs-radar-thisquarter{
    color: darkgray;
    position: relative;
    bottom: 70px;
    font-size: larger;
    text-align: center;
}
.input-search{
      position: relative;
    right: 10px;
}
.table_heading{
  left: 20px;
  position: relative;
}
.vs-table--tbody-table tr{
  /* background-color:#ffbc00; */
  font-family: sans-serif;
  font-size: smaller;
  /* border: 1px solid black; */
  

}
/* .vs-radar-thisquarter-text{
    color: darkgray;
    position: relative;
    bottom: 40px;
    font-size: larger;
} */
</style>

